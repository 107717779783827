@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1366px;
  margin: 0 auto;
}