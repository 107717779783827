@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1366px;
  margin: 0 auto;
}
.App {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  padding: 2.25rem 2.25rem 0 2.25rem;
}
.App > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  width: 7.8125rem;
  height: 1.75rem;
  margin-bottom: 5rem;
}

.App-link {
  color: #61dafb;
}

h2,
h1 {
  font-size: 1.875rem;
  color: #1e1c24;
  margin-bottom: 2.5625rem;
  font-weight: bold;
}

p {
  color: #686868;
  font-size: 1rem;
  margin-bottom: 2rem;
}

button,
.specialist {
  background-color: #01cfff;
  width: 100%;
  max-width: 19.875rem;
  height: 3.125rem;
  font-size: 0.875rem;
  color: #ffffff;
  border-radius: 1.5625rem;
  border: none;
  margin-bottom: 2rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  padding: 1rem;
}
h3 {
  font-size: 1.4375rem;
  color: #1e1c24;
  margin-bottom: 1.625rem;
  text-align: center;
}

.grid-item > .horizontal-scroll {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow-x: scroll;
  width: calc(100% + 4.5rem);
  padding: 1rem;
  align-self: center;
  margin-bottom: 5.4375rem;
}

@media (max-width: 600px) {
  ::-webkit-scrollbar {
    height: 0.4375rem;
    /* border-radius: 0.625rem; */
  }

  ::-webkit-scrollbar-thumb {
    background: #01dbff;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-track {
    background: #e8e8e8;
  }
}

.card {
  border-radius: 0.6875rem;
  width: 16.375rem;
  height: 21.4375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1.1875rem;
  flex: none;
  max-width: 16.375rem;
  flex-grow: 3;
  margin: 0 1rem;
}

.card:last-child {
  margin-right: 1rem;
}

.card > div {
  width: 4.75rem;
  height: 4.75rem;
  background: #363636;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card img {
  margin: 0;
  color: #ffffff;
}
h4 {
  font-size: 1.3125rem;
  margin-bottom: 1.6875rem;
}
.card > p {
  color: #000;
  text-align: center;
  line-height: 1.5625rem;
}

.imgs {
  width: 11.75rem;
  height: 12.1875rem;
}

footer {
  background-color: #1e1e1e;
  border-radius: 1.25rem 1.25rem 0 0;
  width: calc(100% + 4.5rem);
  height: 25.375rem;
  margin-top: 20.125rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

footer div {
  width: calc(100% - 4.5rem);
  height: 31rem;
  background-color: #2d2d2d;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: -15.0625rem;
  text-align: center;
  padding: 0 1rem;
}

footer div h4 {
  /* margin-top: 1rem; */
}

footer div p {
  color: #fff;
  line-height: 1.875rem;
}
footer div img {
  margin-top: -3.6rem;
}

footer div .specialist {
  color: #2d2d2d;
  font-weight: bold;
  text-transform: uppercase;
}

.fixed {
  background-color: #ffdf00;
  height: 5.875rem;
  width: 100%;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}
.fixed > img {
  width: 7.75rem;
  height: 1.75rem;
}
.fixed-arrow {
  border-radius: 0;
  height: 100%;
  background-color: #dbc635;
  display: flex;
  margin: 0;
  width: 5.4375rem;
  cursor: pointer;
  justify-content: center;
}
.fixed-arrow :hover {
  background-color: darken(#dbc635, 50%);
}
.fixed-arrow img {
  margin: 0;
  /* align-self: center; */
}
.fixed > div > img {
  width: 1.125rem;
  height: 1.125rem;
  align-self: center;
}
.fixed-logo {
  margin: 0 0 0 2.25rem;
}

/* mobile */

.grid-item {
  width: 90vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.A {
  grid-area: A;
}
.B {
  grid-area: B;
}
.C {
  grid-area: C;
}
.D {
  grid-area: D;
}
.E {
  grid-area: E;
}
.F {
  grid-area: F;
}

.grid {
  width: auto;
  height: auto;
  display: grid;
  grid-template-areas:
    "A"
    "B"
    "C"
    "D"
    "E"
    "F";
}

.G {
  display: none;
}

/* desktop */
@media (min-width: 601px) {
  .G {
    display: flex;
  }
  .App-logo {
    width: 14.875rem;
    height: 3.3125rem;
  }

  h1 {
    font-size: 4.375rem;
  }

  h2, h3 {
    font-size: 3.125rem;
  }

  h4 {
    font-size: 1.875rem;
  }

  h4:last-child{
    font-size: 2.5rem;
  }

  p {
    font-size: 1.25rem;
  }

  .grid-item > .horizontal-scroll {
    justify-content: center;
    overflow-x: hidden;
  }

  .grid-item {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    align-self: center;
  }

  .grid {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto, 4fr);
    grid-template-areas:
      "A G"
      "B B"
      "D C"
      "E F";
  }

  .imgs {
    width: 75%;
    height: 75%;
  }

  button,
  .specialist {
    font-size: 1.4375rem;
    width: 24rem;
    height: 4.8125rem;
    border-radius: 2.375rem;
    padding: 1.4rem;
    max-width: 100%;
  }

  .card {
    width: 22.6875rem;
    height: 29.75rem;
    max-width: 100%;
    margin: 0 2rem;
    padding: 0 1.6875rem;
  }

  .card > div {
    width: 6.5625rem;
    height: 6.5625rem;
  }

  footer {
    width: 99vw;
  }
  footer div {
    width: 66%;
  }
}

